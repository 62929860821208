var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "险种对比", back: true } }),
      _c(
        "el-table",
        {
          key: _vm.changeKey,
          attrs: {
            data: _vm.tableData,
            border: "",
            "span-method": _vm.arraySpanMethod,
            height: _vm.height,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { fixed: "", prop: "title", width: "130" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.$index != 0 &&
                      scope.$index != 7 &&
                      scope.$index != 9 &&
                      scope.$index != 12 &&
                      scope.$index != 14 &&
                      scope.$index != 18 &&
                      scope.$index != 21 &&
                      scope.$index != 23 &&
                      scope.$index != 28
                        ? _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v(_vm._s(scope.row.title))]
                          )
                        : _c("div", { staticClass: "theme-color" }, [
                            _vm._v(_vm._s(scope.row.title)),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  {
                    staticClass: "theme-color",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v("重疾险")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sub-top-name",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _vm._v("共"),
                    _c("span", { staticClass: "theme-color" }, [
                      _vm._v(_vm._s(_vm.total)),
                    ]),
                    _vm._v("种保险"),
                  ]
                ),
              ]),
            ],
            2
          ),
          _vm._l(_vm.tableTitle, function (item, index) {
            return [
              _c(
                "el-table-column",
                {
                  key: index,
                  attrs: { prop: item.flag, width: "130" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.$index != 13 &&
                            scope.$index != 29 &&
                            scope.$index != 27
                              ? _c("div", {
                                  staticStyle: { "text-align": "center" },
                                  domProps: {
                                    innerHTML: _vm._s(scope.row[item.flag]),
                                  },
                                })
                              : _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(scope.row[item.flag]),
                                  },
                                }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "text-align": "center",
                          position: "relative",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "80px", height: "50px" },
                          attrs: {
                            src: require("@/assets/img/" + item.logo + ".png"),
                          },
                        }),
                        _c("div", { staticClass: "iname" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }