<template>
  <div>
    <Header :title="'险种对比'" :back="true"/>
    <el-table
      :data="tableData"
      border
      :key="changeKey"
      :span-method="arraySpanMethod"
      :height="height">
    <el-table-column
      fixed
      prop="title"
      width="130">
      <template slot="header">
        <!-- <el-popover
          placement="bottom"
          trigger="click">
          <el-checkbox-group v-model="checkedTypes">
            <el-checkbox v-for="type in types" :label="type" :key="type">{{type}}</el-checkbox>
          </el-checkbox-group>
            <div slot="reference">
              <div class="theme-color">重疾险<i class="el-icon-caret-bottom"></i></div>
            </div>
        </el-popover> -->
        <div class="theme-color" style="text-align:center">重疾险</div>
        <div class="sub-top-name" style="text-align:center">共<span class="theme-color">{{total}}</span>种保险</div>
      </template>
      <template slot-scope="scope">
        <div style="text-align:center" v-if="scope.$index!=0&&scope.$index!=7&&scope.$index!=9&&scope.$index!=12&&scope.$index!=14&&scope.$index!=18&&scope.$index!=21&&scope.$index!=23&&scope.$index!=28" >{{scope.row.title}}</div>
        <div class="theme-color" v-else>{{scope.row.title}}</div>
      </template>
    </el-table-column>

    <template v-for="(item, index) in tableTitle" > 
    <el-table-column
      :key="index"
      :prop="item.flag"
      width="130">
      <template slot="header">
        <div style="width:100%;height:100%;text-align:center;position:relative">
          <!-- <div style="position:absolute;right:-8px" @click="closeIns(item)"><i style="font-size:1.5rem" class="el-icon-circle-close theme-color"></i></div> -->
          <img style="width:80px;height:50px;" :src="require('@/assets/img/'+item.logo+'.png')"/>
          <div class="iname">{{item.name}}</div>
        </div>
      </template>
      <template slot-scope="scope">
        <!-- <div style="text-align:center"></div> -->
        <div style="text-align:center" v-if="scope.$index!=13&&scope.$index!=29&&scope.$index!=27"  v-html="scope.row[item.flag]"></div>
        <div v-else v-html="scope.row[item.flag]"></div>
      </template>
    </el-table-column>

    </template>
  </el-table>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  watch:{
    checkedTypes(value){
      // console.log(value)
      this.changeKey = !this.changeKey
      this.total = value.length
      this.tableTitle = this.tempTitle.filter(item=>value.indexOf(item.name)!=-1)
    }
  },
  data(){
    return{
      height:"",
      changeKey:false,
      checkedTypes:['爱伴航', '守护186加强版','活耀人生危疾保2'],
      types:['爱伴航', '守护186加强版','活耀人生危疾保2'],
      tableTitle:[{
        name:"爱伴航",
        logo:"AIA2",
        flag:"num1"
      },{
        name:"守护186加强版",
        logo:"富通",
        flag:"num2"
      },{
        name:"活耀人生危疾保2",
        logo:"宏利",
        flag:"num3"
      }],
      tableData:[{
        title:'受保护疾病数量',
      },{
        title:'总数',
        num1:'115',
        num2:'169',
        num3:'113'
      },{
        title:'重疾',
        num1:'58',
        num2:'69',
        num3:'60'
      },{
        title:'早期疾病',
        num1:'44',
        num2:'83',
        num3:'45'
      },{
        title:'赔偿金额',
        num1:'20%（须作手术之脑动脉瘤50%，骨质疏松症连骨折10%）',
        num2:'20%（严重程度1）、50%（严重程度2）',
        num3:'20%（骨质疏松症连骨折10%）'
      },{
        title:'儿童疾病',
        num1:'13',
        num2:'17',
        num3:'8'
      },{
        title:'赔偿金额',
        num1:'20%',
        num2:'20%',
        num3:'20%'
      },{
        title:'等候期'
      },{
        title:'等候期',
        num1:'90天',
        num2:'60天',
        num3:'90天'
      },{
        title:'保费豁免'
      },{
        title:'赔付早期疾病后',
        num1:'无',
        num2:'有（在严重程度2之50%赔付后终身豁免保费）',
        num3:'无'
      },{
        title:'赔付重疾后',
        num1:'终身豁免保费',
        num2:'终身豁免保费',
        num3:'终身豁免保费'
      },{
        title:'赠送保额'
      },{
        title:'赠送保额',
        num1:'【重疾及身故】<div>0-30岁 首10年+50%（相应提高预支金额）、</div><div>31岁及以上首10年+35%（相应提高预支金额）</div>',
        num2:'【重疾及身故】<div>0-30岁 首10年+50%（相应提高预支金额）、</div><div>31岁及以上首10年+35%（相应提高预支金额）</div>',
        num3:'【仅限重疾】<div>0-30岁 首10年+50%（相应提高预支金额）、</div><div>31岁及以上首10年+35%（相应提高预支金额）</div>'
      },{
        title:'原位癌保障'
      },{
        title:'受保氛围',
        num1:'<div>所有器官</div><div>（皮肤癌除外）</div>',
        num2:'<div>所有器官</div><div>（皮肤癌除外）</div>',
        num3:'12个受保器官'
      },{
        title:'可赔次数',
        num1:'2',
        num2:'2',
        num3:'2'
      },{
        title:'赔偿金额',
        num1:'20%保额<div>（最多5万美元）</div>',
        num2:'20%保额<div>（最多5万美元）</div>',
        num3:'20%保额<div>（最多5万美元）</div>'
      },{
        title:'通波仔保障'
      },{
        title:'可赔次数',
        num1:'1',
        num2:'1',
        num3:'1'
      },{
        title:'赔偿金额',
        num1:'20%保额<div>（最多5万美元）</div>',
        num2:'20%保额<div>（最多5万美元）</div>',
        num3:'20%保额<div>（最多5万美元）</div>'
      },{
        title:'癌症/中风/心脏病'
      },{
        title:'每次保额/次数（多次赔偿）',
        num1:'100%/7次<div>(涵盖脑退化疾病)</div>',
        num2:'100%/5次',
        num3:'100%/5次'
      },{
        title:'癌症多重保障'
      },{
        title:'最多赔偿次数',
        num1:'5',
        num2:'5',
        num3:'3'
      },{
        title:'每次赔偿保额',
        num1:'100%',
        num2:'100%',
        num3:'100%'
      },{
        title:'等候期',
        num1:'3年（不分新症与复发）',
        num2:'新症1年，复发3年',
        num3:'3年（不分新症与复发）'
      },{
        title:'其他癌症相关保障',
        num1:'持续癌症现金选项：缩短等候期至1年，可选择每月收取 5%原有保额，长达100个月或直至受保人年届85岁，以较先者为准（需积极治疗/晚期护理证明）',
        num2:'关爱援助保障：每月额外赔偿保额1%，每次最长18个月，最多4次，下次确诊癌症/中风/心脏病理赔时扣除相应保额（毋须积极治疗/晚期护理证明）',
        num3:'癌症治疗加强保障：缩短等候期至1年，每次30%保额，最多6次，不影响多重赔偿次数（需积极治疗/晚期护理证明）'
      },{
        title:'其他特色保障'
      },{
        title:'其他特色保障',
        num1:'<div>• 深切治疗保障赔偿（级别一预支20%，级别二赔偿100%）</div> <div>• 脑退化症终身年金（每年派发原有保额6%直到保单终止）</div> <div>• 首护挚宝保(为腹中婴儿投保)</div>',
        num2:'<div>• 确诊心脏病/中风也可获得关爱援助保障</div> <div>• 良性肿瘤额外赔偿2次 </div> <div>• 呼吸系统疾病额外60%保额</div> <div>• 环球紧急支援服务</div> <div>• 保障还原利益，理赔早期疾病/儿童疾病，1年后可还原100%保额</div>',
        num3:'<div>• 生活守护保障(确诊后连续180日无法进行两项或上日常活动，额外给付100%保额</div> <div>• 通胀加保权益</div> <div>• 终期红利锁定选项</div> <div>• 转介服务 (由美国医院提供第二医療意見)</div>'
      }],
      total:3,
    }
  },
  created(){
    this.tempTitle = JSON.parse(JSON.stringify(this.tableTitle))
    this.height = document.body.scrollHeight -16*3
  },
  methods:{
    // tableRowClassName({row, rowIndex}) {
    //  if (row&&rowIndex===0||rowIndex===7||rowIndex===9||rowIndex===12||rowIndex===14||rowIndex===18||rowIndex===21||rowIndex===23||rowIndex===28) {
    //     return 'theme-color';
    //   }
    //   return '';
    // },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
     if (row&&column&&rowIndex===0||rowIndex===7||rowIndex===9||rowIndex===12||rowIndex===14||rowIndex===18||rowIndex===21||rowIndex===23||rowIndex===28) {
        if (columnIndex === 0) {
          return [1, 4];
        }else{
          return [0,0];
        }
      }
    },
    closeIns(list){
      this.tableTitle = this.tableTitle.filter(item=>item.flag!=list.flag)
      this.checkedTypes=this.checkedTypes.filter(item=>item!=list.name)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-checkbox-group{
  z-index:3;
  width: 80px;
  .el-checkbox{
    margin:5px 10px;
  }
}
</style>